import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

const LanguageHandler = ({ changeLanguage }) => {
  const { lang } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (lang) {
      changeLanguage(lang)
      navigate(`/${lang}`)
    }
  }, [lang, changeLanguage, navigate])

  return null
}

export default LanguageHandler