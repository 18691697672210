import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import "./App.css"
import { Home } from "./pages/home/Home"
import { Route, Routes } from "react-router-dom"
import i18n from "./i18next"
import LanguageHandler from "./LanguageHandler"

function App() {
  const [lang, setLang] = useState("ru")

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setLang(lng)
  }

  useEffect(() => {
    // Извлечение языка из localStorage при загрузке компонента
    const savedLang = localStorage.getItem("lang")
    if (savedLang && savedLang !== lang) {
      setLang(savedLang)
      i18n.changeLanguage(savedLang)
    }
  }, [])

  useEffect(() => {
    // Сохранение языка в localStorage при его изменении
    localStorage.setItem("lang", lang)
  }, [lang])

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <meta charSet='utf-8' />
        <meta name='robots' content='all' />
        <meta name='description' content='Расширьте возможности своего бизнеса' data-rh='true' />
        <meta name='keywords' content='дистрибуция, международная торговля, организация спортивных мероприятий, аренда' />
        <title>Расширьте возможности своего бизнеса</title>
        <link rel='alternate' hrefLang='ru' href='https://rapaco.global' />
        <link rel='alternate' hrefLang='en' href='https://rapaco.global/en' />
      </Helmet>

      <Routes>
        <Route path='/' element={<Home setLang={changeLanguage} lang={lang} />} />
        <Route path='/:lang' element={<Home setLang={changeLanguage} lang={lang} />} />
        <Route path='/change-lang/:lang' element={<LanguageHandler changeLanguage={changeLanguage} />} />
      </Routes>
    </>
  )
}

export default App
