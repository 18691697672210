import React from "react"
import { Link } from "react-router-dom"
import s from "./Home.module.css"
import { useTranslation } from "react-i18next"

export const Home = ({ setLang, lang }) => {
  const { t } = useTranslation()
  const cards = t("cards", { returnObjects: true })

  return (
    <>
      <header className={s.header}>
        <nav>
          <ul className={s.leng_nav}>
            <Link to='/en'>
              <li className={lang === "en" ? `${s.orange}` : `${s.black}`} onClick={() => setLang("en")}>
                ENG
              </li>
            </Link>
            <Link to={"/"}>
              <li className={lang === "ru" ? `${s.orange}` : `${s.black}`} onClick={() => setLang("ru")}>
                RU
              </li>
            </Link>
          </ul>
        </nav>
      </header>
      <section>
        <h1 className={s.title}>{t("title")}</h1>
        <div className={s.bridge_img}></div>
        <div className={s.container}>
          <div className={s.cards_block}>
            {cards.map((elem) => (
              <div key={elem.key} className={s.card}>
                <div className={s.card_logo__mobail} style={{ backgroundImage: `url('${elem.logo}')` }}></div>
                <div className={s.card_img}>
                  <div className={s.card_img__top} style={{ backgroundImage: `url('${elem.top_img}')` }}></div>
                  <div className={s.card_img__botton} style={{ backgroundImage: `url('${elem.botton_img}')` }}></div>
                </div>
                <div className={s.card_info}>
                  <div className={s.logo_text}>
                    <div className={s.card_logo} style={{ backgroundImage: `url('${elem.logo}')` }}></div>
                    <div className={s.description}>{elem.descriptoin}</div>
                  </div>
                  <a className={s.card_button} href={elem.button_url} target='_blank' rel='noopener noreferrer'>
                    {elem.button_text}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className={s.ending}>
            <h2 className={s.ending_title}>{t("ending")}</h2>
          </div>
        </div>
      </section>
    </>
  )
}
